import React, { useCallback, useContext, useEffect, useState } from "react";
import Cell, { Role } from "./Cell";
import Room, { ReferenceCircle } from "./Room";
import { useAPIGet } from "./useAPIGet";
import { useTopicEvent } from "../socket/ChannelProvider";
import LoadingContext from "../controls/LoadingContext";

type Silo<T> = {
  [key: string]: T;
};

type PlaceData = {
  isLoading: boolean;
  name: string;
  organizationID: string;
  cells: Silo<Cell>;
  rooms: Silo<Room>;
};

const initialPlaceData: PlaceData = {
  isLoading: true,
  name: "",
  organizationID: "",
  cells: {},
  rooms: {},
};

type Place = {
  name: string;
  place_id: string;
  organization_id: string;

  rooms: {
    name: string;
    room_id: string;
    place_id: string;

    reference_circle?: {
      x_m: number;
      y_m: number;
      radius_m: number;
      rotation_deg: number;
    };

    cells: {
      serial_number: string;
      role: string;
      role_pinned: boolean;

      model?: string;

      online: boolean;
      last_seen_at?: string;
      ssid?: string;
      bssid?: string;
      ip_address?: string;
      rssi?: number;
      wifi_channel?: number;

      syng_os_version?: string;

      calibrated: boolean;
      syfi_good: boolean;
      time_sync_good: boolean;

      ota?: {
        code: number;
        name: string;
        progress: number;
        size: number;
        status:
          | "downloading"
          | "installing"
          | "rebooting"
          | "success"
          | "failure"
          | "already_installed"
          | "aborted";
        update_mode: "manual" | "notify" | "automatic";
      };

      position: {
        x: number;
        y: number;
        z: number;
        rotation: number;
      };

      height_layer: number;
    }[];
  }[];
};

const PlaceDataContext = React.createContext(initialPlaceData);

function parseDate(str?: string): Date | undefined {
  if (!str) {
    return undefined;
  }

  return new Date(str);
}

function translateReferenceCircle(rc?: {
  x_m: number;
  y_m: number;
  radius_m: number;
  rotation_deg: number;
}): ReferenceCircle | undefined {
  if (!rc) {
    return undefined;
  }

  return {
    x: rc.x_m,
    y: rc.y_m,
    radius: rc.radius_m,
    rotation: rc.rotation_deg,
  };
}

function optionalMap<U, V>(
  u: U | undefined,
  transform: (u: U) => V
): V | undefined {
  if (!u) {
    return undefined;
  }

  return transform(u);
}

const PlaceDataProvider = (props: {
  placeID?: string;
  children: React.ReactNode;
}) => {
  const [placeData, setPlaceData] = useState(initialPlaceData);
  useEffect(() => {
    setPlaceData(initialPlaceData);
  }, [props.placeID]);

  const callback = useCallback(
    (object: any) => {
      const rooms: Silo<Room> = {};
      const cells: Silo<Cell> = {};

      const place = object as Place;
      for (const room of place.rooms) {
        const cellIdentifiers = room.cells.map(
          ({ serial_number }) => serial_number
        );
        rooms[room.room_id] = {
          name: room.name,
          roomID: room.room_id,
          placeID: room.place_id,
          cellIdentifiers: cellIdentifiers,

          referenceCircle: translateReferenceCircle(room.reference_circle),
        };

        for (const cell of room.cells) {
          cells[cell.serial_number] = {
            serialNumber: cell.serial_number,
            model: cell.model,
            placeID: place.place_id,
            roomID: room.room_id,
            role: cell.role as Role,
            rolePinned: cell.role_pinned,
            isOnline: cell.online,
            lastSeen: parseDate(cell.last_seen_at),
            ssid: cell.ssid,
            bssid: cell.bssid,
            ipAddress: cell.ip_address,
            rssi: cell.rssi,
            wifiChannel: cell.wifi_channel,
            syngOSVersion: cell.syng_os_version,
            position: cell.position,
            height_layer: cell.height_layer,
            isCalibrated: cell.calibrated,
            isSyFiGood: cell.syfi_good,
            isTimeSyncGood: cell.time_sync_good,

            ota: optionalMap(cell.ota, (ota) => {
              return {
                code: ota.code,
                name: ota.name,
                progress: ota.progress,
                size: ota.size,
                status: ota.status,
                updateMode: ota.update_mode,
              };
            }),
          };
        }
      }

      setPlaceData({
        isLoading: false,
        name: place.name,
        organizationID: place.organization_id,
        cells: cells,
        rooms: rooms,
      });
    },
    []
  );

  useAPIGet(callback, props.placeID ? `/places/${props.placeID}` : undefined);
  useTopicEvent(`place:${props.placeID ?? ""}`, "changed", callback);

  return (
    <LoadingContext.Provider value={placeData.isLoading}>
      <PlaceDataContext.Provider value={placeData}>
        {props.children}
      </PlaceDataContext.Provider>
    </LoadingContext.Provider>
  );
};

const usePlaceData = () => {
  return useContext(PlaceDataContext);
};

export default PlaceDataProvider;
export { type Silo, usePlaceData };
